body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  // background-color: rgb(244, 245, 247) !important;
}

html,
body {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slick-dots {
  position: absolute;
  bottom: 100px !important;
  display: block;
  width: 100%;
  padding: 10;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li.slick-active button:before {
  font-size: 15px;
  color: white !important;
  opacity: 1 !important;
}

.slick-dots li button:before {
  font-size: 15px !important;
  color: white !important;
  opacity: 0.6 !important;
}

@font-face {
  font-family: "alphakind";
  src: local("alphakind"),
    url(./fonts/alphakind/alphakind-webfont.woff) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dosis";
  src: local("dosis"),
    url(./fonts/dosis/dosis-medium-webfont.woff2) format("truetype");
  font-weight: normal;
  font-style: normal;
}

:host {
  --color--transverse: #283276;
  --color--transverse-hover: #192258;
  --color--transverse-active: #0e1645;
  --border-radius--btn: 100px;
}

.ft {
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .btn-group-lg > .btn,
  .btn-lg {
    padding: 0 1.5rem;
    min-height: 2.5rem;
    font-size: 0.8125rem;
  }
  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    border: 1px solid transparent;
    user-select: none;
    font-weight: 500;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .btn:focus {
    box-shadow: 0 0 0 0.125rem #414042 inset, 0 0 0 0.25rem #fff inset;
  }
  .btn-transverse.dark {
    background-color: var(--color--transverse);
    border-color: var(--color--transverse);
    color: #fff;
  }
  .btn-transverse.light {
    background-color: #fff;
    border-color: var(--color--transverse);
    color: var(--color--transverse);
  }
  .btn-transverse.dark:focus,
  .btn-transverse.dark:not(:disabled):not(.disabled):active {
    background-color: var(--color--transverse-active);
    border-color: var(--color--transverse-active);
    color: #fff;
  }
  .btn-transverse.light:not(:disabled):not(.disabled):active {
    background-color: #fff;
    border-color: var(--color--transverse-active);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25) inset;
  }
  .btn-transverse.light:focus {
    background-color: #f5f5f5;
    border-color: var(--color--transverse-active);
    color: var(--color--transverse-active);
  }
  .btn-transverse.dark:hover {
    background-color: var(--color--transverse-hover);
    border-color: var(--color--transverse-hover);
    color: #fff;
  }
  .btn-transverse.light:hover {
    background-color: #f5f5f5;
    border-color: var(--color--transverse-hover);
    color: var(--color--transverse-hover);
  }
  img,
  svg {
    vertical-align: middle;
    width: 16px;
    margin-right: 8px;
  }
}
